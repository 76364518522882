import React from 'react';
import Company from '../Company';

// images
import Coolblue from '../../assets/logo/coolblue.svg';
import Efteling from '../../assets/logo/efteling.svg';
import ABNAmro from '../../assets/logo/abn-amro.svg';
import Heineken from '../../assets/logo/heineken.svg';
import Vodafone from '../../assets/logo/vodafone.svg';
import NationaleNederlanden from '../../assets/logo/nationale-nederlanden.svg';
import Rabobank from '../../assets/logo/rabobank.svg';
import Picnic from '../../assets/logo/picnic.svg';
import Anwb from '../../assets/logo/anwb.svg';
import MediaMarkt from '../../assets/logo/media-markt.svg';

const companiesList = [{
  name: 'Coolblue',
  imgSrc: Coolblue,
  width: 250,
  height: 250
}, {
  name: 'Efteling',
  imgSrc: Efteling,
  width: 300,
  height: 119
}, {
  name: 'ABN Amro',
  imgSrc: ABNAmro,
  width: 300,
  height: 78
}, {
  name: 'Heineken',
  imgSrc: Heineken,
  width: 186,
  height: 70
}, {
  name: 'Vodafone',
  imgSrc: Vodafone,
  width: 222,
  height: 150
}, {
  name: 'Nationale Nederlanden',
  imgSrc: NationaleNederlanden,
  width: 300,
  height: 72
}, {
  name: 'Rabobank',
  imgSrc: Rabobank,
  width: 186,
  height: 70
}, {
  name: 'Picnic',
  imgSrc: Picnic,
  width: 186,
  height: 70
}, {
  name: 'ANWB',
  imgSrc: Anwb,
  width: 288,
  height: 150
}, {
  name: 'Media markt',
  imgSrc: MediaMarkt,
  width: 300,
  height: 42
}];

const CompanyList = () => {
  return (
    <div>
      <h2>Deze klanten gingen je voor</h2>
      <div className="companiesList">
        {companiesList.map((company, index) => {
          return (
            <Company
              key={ index }
              name={ company.name }
              imgSrc={ company.imgSrc }
              width={company.width}
              height={company.height}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CompanyList;
