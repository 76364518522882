import React from 'react';
import PropTypes from 'prop-types';
import LazyloadImage from "../LazyloadImage";

class Company extends React.Component {
  render() {
    const { name, imgSrc, width, height } = this.props;

    return (
      <div className="company">
        <div className="companyImgWrapper">
          <LazyloadImage
            imgSrc={imgSrc}
            alt={name}
            width={width}
            height={height}
            maxHeight={70}
            imgClassName="companyImg"
          />
        </div>
        {/*<img data-src={ imgSrc } alt={ name } className="companyImg lazyload" />*/}
      </div>
    )
  }
}

Company.propTypes = {
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Company;
