import React from "react";
import PropTypes from 'prop-types';
import classNameFactory from "classnames";

import "./index.scss";

class Button extends React.Component {
  render() {
    const {children, className, small, buttonCta, ...otherProps} = this.props;

    const classname = classNameFactory({
      [className]: className,
      "button": true,
      "buttonCta": buttonCta,
      "buttonSmall": small
    });

    return (
      <a className={ classname } { ...otherProps }>
        { children }
      </a>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  buttonCta: PropTypes.bool,
  small: PropTypes.bool
};

export default Button;
