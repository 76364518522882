import React from 'react';

import marcel from '../../assets/reviewer/marcel.jpg'
import wout from '../../assets/reviewer/wout.jpg'
import bart from '../../assets/reviewer/bart.jpg'
import LazyloadImage from "../LazyloadImage";

const REVIEWS = [{
  image: marcel,
  name: 'Marcel de Reus',
  jobDescription: 'Lead developer',
  company: 'Elastique',
  text: 'Altijd up-to-date en eenvoudig te implementeren. Accurater dan Google Maps!'
}, {
  image: wout,
  name: 'Wout Withagen',
  jobDescription: 'Algemeen directeur',
  company: 'Freshheads',
  text: 'Voor veel van onze klanten zetten we Postcode API in. Gemakkelijk te implementeren, betrouwbaar, schaalbaar én betaalbaar voor iedere klant.'
}, {
  image: bart,
  name: 'Bart Welzen',
  jobDescription: 'Founder',
  company: 'Ensie',
  text: 'Postcode API is snel, verhoogt onze conversie en is betrouwbaar. Ook bij hoge pieken.'
}];

class CustomerReviewList extends React.Component {
  render() {
    const reviews = REVIEWS;

    if (!reviews.length) {
      return null;
    }

    return (
      <div className="customerReviews">
        {reviews.map((review, i) => {
          return (
            <div key={i} className="customerReview">
              <div className="customerReviewContent">
                <div className="customerReviewImgContainer">
                  <LazyloadImage
                    imgClassName="customerReviewImg"
                    imgSrc={review.image}
                    alt={review.image}
                    width={100}
                    height={100}
                  />
                </div>
                <p>{review.text}</p>
                <h3 className="customerReviewName">{review.name}</h3>
                <span>{review.jobDescription}</span>
                <span>{review.company}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default CustomerReviewList;
