export const priceList = [
  {
    weight: 1,
    plan: 'rookie',
    headerTitle: 'Rookie',
    cardHighLight: false,
    price: {
      month: '€ 5,00',
      year: '€ 57,00',
      monthYear: '€ 4,75'
    },
    cardContentList: [
      {
        highlightedText: '1.000',
        text: 'calls per maand'
      },
      {
        highlightedText: '5',
        text: 'calls per seconde',
      }
    ]
  }, {
    weight: 2,
    plan: 'small',
    headerTitle: 'Small',
    cardHighLight: false,
    price: {
      month: '€ 13,00',
      year: '€ 148,20',
      monthYear: '€ 12,35'
    },
    cardContentList: [
      {
        highlightedText: '5.000',
        text: 'calls per maand'
      },
      {
        highlightedText: '5',
        text: 'calls per seconde',
      }
    ]
  }, {
    weight: 3,
    plan: 'standard',
    headerTitle: 'Standard',
    cardHighLight: true,
    price: {
      month: '€ 34,00',
      year: '€ 387,60',
      monthYear: '€ 32,30'
    },
    cardContentList: [
      {
        highlightedText: '20.000',
        text: 'calls per maand'
      },
      {
        highlightedText: '10',
        text: 'calls per seconde',
      }
    ]
  }, {
    weight: 4,
    plan: 'plus',
    headerTitle: 'Plus',
    cardHighLight: false,
    price: {
      month: '€ 69,00',
      year: '€ 786,60',
      monthYear: '€ 65,55'
    },
    cardContentList: [
      {
        highlightedText: '50.000',
        text: 'calls per maand'
      },
      {
        highlightedText: '10',
        text: 'calls per seconde',
      }
    ]
  }, {
    weight: 5,
    plan: 'pro',
    headerTitle: 'Pro',
    cardHighLight: false,
    price: {
      month: '€ 119,00',
      year: '€ 1.356,60',
      monthYear: '€ 113,05'
    },
    cardContentList: [
      {
        highlightedText: '100.000',
        text: 'calls per maand'
      },
      {
        highlightedText: '25',
        text: 'calls per seconde',
      },
      {
        highlightedText: 'Premium',
        text: 'support'
      },
      {
        highlightedText: 'Multitenant'
      }
    ]
  }
];

export const cheapestPackage = priceList[0];
