import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import cx from 'classnames';
import {Link as ScrollLink} from 'react-scroll';
import {Link } from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import loadable from '@loadable/component';

// Components
import Section from '../../components/Section';
import Container from '../../components/Container';
import CompanyList from '../../components/CompanyList';
import Button from '../../components/Button';
import CustomerReviewList from "../../components/CustomerReviewList";
import Preview from '../../components/Preview';

// Images
import CheckedSvg from "../../assets/icon/checked.svg";
import EnvelopeSvg from "../../assets/icon/envelope.svg";
import UserExperienceSvg from "../../assets/icon/user-experience.svg";
import UptimeSvg from "../../assets/icon/uptime.svg";
import IntegrationSvg from "../../assets/icon/integration.svg";
import Pages from "../../global/pages";
import {cheapestPackage} from "../../components/CardWrapper/priceList";

// lazyload components
const Animation = loadable(() => import ('../../components/Animation'))

const Homepage = ({
                    head
                  }) => {
  const title = head.metaTitle ? head.metaTitle : head.title;

  const link = [
    {rel: 'apple-touch-icon', sizes: '180x180', href: '/assets/favicon/apple-touch-icon.png'},
    {rel: 'icon', type: 'image/png', sizes: '32x32', href: '/assets/favicon/favicon-32x32.png'},
    {rel: 'icon', type: 'image/png', sizes: '16x16', href: '/assets/favicon/favicon-16x16.png'},
    {rel: 'manifest', href: '/assets/favicon/manifest.json'},
    {rel: 'mask-icon', href: '/assets/favicon/safari-pinned-tab.svg', color: '#5bbad5'},
    {rel: "canonical", href: "https://www.postcodeapi.nu"},
  ];

  return (
    <div>
      <Helmet
        title={title}
        meta={[
          {
            name: "description",
            content: "Nederlandse adressen en postbussen beschikbaar via een API. Ontwikkeld voor en door developers, met als doel: 100% juiste adresinformatie in jouw app, webshops, website en database."
          },
          {property: "og:type", content: "website"},
          {property: "og:title", content: title},
          {
            property: "og:description",
            content: "Nederlandse adressen en postbussen beschikbaar via een API. Ontwikkeld voor en door developers, met als doel: 100% juiste adresinformatie in jouw app, webshops, website en database."
          },
          {property: "og:site_name", content: "Postcode API"},
          {property: "og:url", content: "https://www.postcodeapi.nu"},
          {property: "og:image", content: "https://www.postcodeapi.nu/assets/share-image.png"},
          {property: "og:image:type", content: "image/png"},
          {property: "og:image:width", content: "1024"},
          {property: "og:image:height", content: "512"},
        ]}
        link={link}
        script={[
          {
            type: "application/ld+json",
            innerHTML: `{"@context":"http://schema.org","@type":"Organization","name":"Postcode API B.V.","email":"info@postcodeapi.nu","url":"https://www.postcodeapi.nu","address":{"@type":"PostalAddress","addressLocality":"Nijmegen","addressRegion":"Gelderland","addressCountry":"NL","postalCode":"6521 BC","streetAddress":"Waldeck Pyrmontsingel 12"},"logo":{"@type":"ImageObject","contentUrl":"https://www.postcodeapi.nu/assets/schema-logo.png","width":1200,"height":1200}}`
          }
        ]}
      />
      <Section sectionBrand={true}>
        <Container>
          <div className="introWrapper">
            <div className="introColumn">
              <h2 className="heading heading-page heading-white">De standaard in adresvalidatie</h2>
              <div>
                <ul className="checklist checklist-white">
                  <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Altijd de juiste adresgegevens van je klanten</li>
                  <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Extra gebruiksgemak bij het invullen van formulieren</li>
                  <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>100% uptime en snelle responses gegarandeerd</li>
                  <li><ReactSVG src={CheckedSvg} className="checklist-icon"/>Eenvoudig te implementeren in je applicaties</li>
                </ul>
              </div>
              <div className="introBtnWrapper" >
                <ScrollLink to="preview" href="#preview" offset={-75}
                            className={cx("button" , "buttonOutline" )} smooth={true}>Test de API</ScrollLink>
                <Link to="/gratis-proberen" className={cx("button" , "buttonCta" )}>Gratis proberen</Link>
              </div>
            </div>
            <div className="introColumn" >
              <Animation/>
            </div>
          </div>
        </Container>
      </Section>
      <Section sectionSecondary={true} sectionCenter={true} sectionSkewLeft={true}>
        <Container>
          <CompanyList/>
        </Container>
      </Section>
      <Section sectionCenter={true} sectionSkewRight={true}>
        <Container>
          <Preview/>
        </Container>
      </Section>
      <Section sectionCenter sectionSecondm ary={true} sectionSkewRight={true}>
        <Container>
          <h2 className="heading">Waarom Postcode API?</h2>
          <div className="usps" >
            <div className="uspWrapper" >
              <div className="usp" >
                <div className="uspImageHolder" >
                  <img alt="Postcode API zorgt voor juiste en actuele adresgegevens"
                       data-src={EnvelopeSvg}
                       width={65}
                       height={60}
                       className="uspImage lazyload" />
                </div>
                <div className="uspTextContent" >
                  <h2 className="uspHeading" >Altijd de juiste adresgegevens</h2>
                  <p className="uspDescription" >De data van Postcode API wordt dagelijks geüpdatet. Zo kunnen gebruikers altijd de meest actuele adresgegevens invullen en zijn typefouten in adressen verleden tijd.</p>
                </div>
              </div>
            </div>
            <div className="uspWrapper" >
              <div className="usp" >
                <div className="uspImageHolder" >
                  <img alt="Extra gebruiksgemak door Postcode API"
                       data-src={UserExperienceSvg}
                       width={60}
                       height={60}
                       className="uspImage lazyload" />
                </div>
                <div className="uspTextContent" >
                  <h2 className="uspHeading" >Extra gebruiksgemak</h2>
                  <p className="uspDescription" >Gebruikers zijn sneller klaar met het invullen van formulieren als adresgegevens automatisch worden aangevuld door Postcode API. Dit zorgt voor een betere beleving en hogere conversie.</p>
                </div>
              </div>
            </div>
            <div className="uspWrapper" >
              <div className="usp" >
                <div className="uspImageHolder" >
                  <img alt="100% uptime met Postcode API"
                       data-src={UptimeSvg}
                       width={60}
                       height={60}
                       className="uspImage lazyload" />
                </div>
                <div className="uspTextContent" >
                  <h2 className="uspHeading" >100% uptime en snelle responses</h2>
                  <p className="uspDescription" >Postcode API is volledig serverless op AWS infrastructuur ontwikkeld. Hierdoor kunnen wij snelle responses en een hoge uptime garanderen, sinds de release in 2017 is dit zelfs 100%.</p>
                </div>
              </div>
            </div>
            <div className="uspWrapper" >
              <div className="usp" >
                <div className="uspImageHolder" >
                  <img
                      alt="Postcode API is eenvoudig te implementeren"
                      data-src={IntegrationSvg}
                      width={66}
                      height={60}
                      className="uspImage lazyload" />
                </div>
                <div className="uspTextContent" >
                  <h2 className="uspHeading" >Eenvoudig te implementeren</h2>
                  <p className="uspDescription" >Postcode API is eenvoudig te implementeren in je eigen applicaties. In een handomdraai maak je de eerste API call vanaf jouw website, app of webshop, ongeacht programmeertaal of CMS.</p>
                </div>
              </div>
            </div>
            </div>
        </Container>
      </Section>
      <Section sectionCenter sectionSkewRight>
        <Container>
          <h2 className="heading">Eenvoudig te implementeren</h2>
          <p className="sectionDescription">
            Postcode API streeft altijd naar de beste <em>Developer Experience</em>. Door de eenvoudige interface van de REST API hoef
            je alleen een HTTP request met postcode en huisnummer te doen om straat en plaats in JSON formaat terug te krijgen.
            Uiteraard zijn er ook kant-en-klare SDK's en plugins beschikbaar voor onder andere Magento, Wordpress en WooCommerce.
          </p>
           <div className={"implementationImg"} />
          <Link to="/docs" className={ cx("button" , "buttonTertiary" )}>Naar de docs</Link>
          <Link to={Pages.plugins} className={ cx("button" , "buttonTertiary" )}>Naar de plugins</Link>
        </Container>
      </Section>
      <Section sectionCenter sectionSkewLeft sectionSecondary>
        <Container>
          <h2 className="heading">Hoe gebruiken klanten Postcode API?</h2>
          <p className="sectionDescription">Postcode API wordt gebruikt door onder andere internetbureaus, online dienstverleners, webshops en goede doelen. Een aantal klanten aan het woord.</p>
          <CustomerReviewList/>
        </Container>
      </Section>
      <Section sectionCenter sectionSkewRight sectionBrandInvert showBg={false}>
        <Container>
          <h2 className="heading heading-white">Klaar voor de start?</h2>
          <p className={cx("sectionDescription", "sectionDescriptionCentered")}>
            Overtuigd dat Postcode API de service is die je nodig hebt? Al vanaf {cheapestPackage.price.month} per maand behoren foute adresgegevens tot het verleden!
          </p>
          <Button href="/gratis-proberen" className={cx("button" , "buttonCta" )}>Gratis proberen</Button>
        </Container>
      </Section>
    </div>
  );
};

Homepage.propTypes = {
  head: PropTypes.object.isRequired,
};

export default Homepage;
